"use client";

import React, { FC } from "react";
import Image from "next/image";
import { FaCheckCircle } from "react-icons/fa";

interface Block {
  imageSrc?: string;
  subheading: string;
  desc: string;
}

interface LaunchYourProjectProps {
  title: string;
  description: string;
  blocks: Block[];
  imageSrc?: string;
  conclusion?: string;
}

const LaunchYourProject: FC<LaunchYourProjectProps> = ({
  title,
  description,
  blocks,
  imageSrc = "/images/launchyourproject/web-development-project.png",
  conclusion = "",
}) => {
  return (
    <section className="bg-color-brown-200 pt-10 relative overflow-hidden">
      <div className="max-w-screen-lg mx-auto">
        <h2 className="text-center font-bold mb-2 mx-6 md:mx-auto">{title}</h2>
        <p className="text-center">{description}</p>
      </div>
      <div className="max-w-screen-2xl mx-auto mt-2 md:mt-4 pt-2 md:pt-10 flex flex-col md:flex-row items-center md:items-start gap-10">
        {/* Left Image */}
        <div className="md:w-1/3 w-full flex items-end justify-end">
          <Image
            src={imageSrc}
            alt={title}
            width={400}
            height={450}
            className="object-contain"
          />
        </div>

        {/* Right Content */}
        <div className="md:w-2/3 w-full bg-white pb-2 md:mr-36">
          {blocks.map((item, index) => (
            <div key={index} className="p-5 pb-0">
              <div className="flex items-start">
                <div className="font-sans">
                  <h3 className="text-color-blue-800 text-2xl mb-2">
                    <div className="flex items-center">
                      <FaCheckCircle className="text-green-500 mr-2" />
                      <span>{item.subheading}</span>
                    </div>
                  </h3>
                  <p className="text-base">{item.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {conclusion && (
        <div className="max-w-screen-lg mx-auto my-10">
          <p className="text-center text-2xl">{conclusion}</p>
        </div>
      )}
    </section>
  );
};

export default LaunchYourProject;
