"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import React, { memo } from "react";
import { childVariants, parentVariants } from "../constants/motion";

const revealVariant = {
  visible: {
    opacity: 1,
    y: 0,
    clipPath: "inset(0 0 0 0)",
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    y: 50,
    clipPath: "inset(0 0 100% 0)",
    scale: 1.05, // Start slightly larger to scale down
  },
};

const ArticleBox = memo(({ title = "", image, children }) => {
  return (
    <motion.section
      variants={parentVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="bg-color-brown-100"
    >
      <div className="section flex flex-col justify-center">
        <div className="w-full flex flex-col md:flex-row justify-center">
          <div className="flex-1 py-4 px-8 md:px-36 py-10">
            <motion.h2
              variants={childVariants}
              className="text-color-brown-800 mb-4"
            >
              {title}
            </motion.h2>
            <motion.div variants={childVariants}>{children}</motion.div>
          </div>
          <motion.div
            className="flex-1 relative min-h-64"
            variants={revealVariant}
            viewport={{ once: true }}
          >
            {image && (
              <Image
                src={image.src}
                alt={image.alt}
                fill
                className="absolute inset-0 w-full h-full object-cover"
              />
            )}
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
});

export default ArticleBox;
