const parentVariants = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const childVariants = {
  initial: { y: 20, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

const itemVariants = {
  left: {
    initial: { x: -40, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { x: { type: "spring", stiffness: 60 } },
  },
  right: {
    initial: { x: 40, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    transition: { x: { type: "spring", stiffness: 60 } },
  },
};

const parentVariantsExtended = {
  initial: { y: 60, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
    },
  },
  viewport: {
    once: false, // Animation will repeat every time it comes into view
    amount: 0.2, // Percentage of the element that needs to be visible for the animation to trigger
  },
};

const childVariantsExtended = {
  initial: { y: 60, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

export {
  parentVariants,
  childVariants,
  itemVariants,
  parentVariantsExtended,
  childVariantsExtended,
};

export default parentVariants;
