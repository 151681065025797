"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import { childVariants, parentVariants } from "@/constants/motion";

const Intro = ({ h1_text, children, image, h2_text_coloured = "" }) => {
  return (
    <motion.section>
      <motion.div
        className="flex flex-col md:flex-row max-w-screen-2xl mx-auto bg-white my-20"
        variants={parentVariants}
        initial="initial"
        whileInView="animate"
      >
        <motion.div
          className="flex-1 relative min-h-48"
          variants={childVariants}
        >
          <Image
            fill
            src={image.src}
            alt={image.alt}
            className="absolute object-cover w-full h-full"
          />
        </motion.div>

        <div className="flex-1 p-6 md:p-10">
          <motion.h2
            variants={childVariants}
            className="text-left font-bold mb-2 text-color-blue-800 leading-tight"
          >
            {h1_text}
            {h2_text_coloured && (
              <span className="text-color-orange-400 pl-2">
                {h2_text_coloured}
              </span>
            )}
          </motion.h2>
          <motion.div className="w-full mt-2" variants={childVariants}>
            {children}
          </motion.div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Intro;
