"use client";
import React from "react";
import { Accordion } from "flowbite-react";
import FormContactUs from "./FormContactUs";

const Faq = ({ title, faqs }) => {
  return (
    <section className="bg-color-brown-900 py-8 md:py-14">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h2 className="font-bold text-white mb-10">{title}</h2>
        <div className="flex flex-col md:flex-row gap-3 md:gap-10 items-start justify-start">
          <div className="md:basis-3/5 text-neutral-300">
            <Accordion className="accordion text-white" collapseAll>
              {faqs.map((faq, index) => (
                <Accordion.Panel key={index}>
                  <Accordion.Title>{faq.question}</Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-white">{faq.answer}</p>
                  </Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
          </div>
          <div className="md:basis-2/5 text-white">
            <div className="text-white font-sans mb-4">
              Kindly fill in the form and we will get back to you.
            </div>
            <FormContactUs consentText="text-white" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Faq);
